<template>
  <div class="taskInfo">
    <div class="taskInfo-layout">
      <van-form @submit="onSubmit">
        <div class="body">
          <van-field class="uploaderRequire" name="uploader" label="交付物附件">
            <template #input>
              <van-uploader
                :max-size="10 * 1024 * 1024"
                v-model="fj"
                accept="image/*"
                :after-read="afterRead"
                @delete="delImg"
                :max-count="8"/>
            </template>
          </van-field>
          <van-field
            v-model="remark"
            rows="3"
            autosize
            label="备注"
            type="textarea"
            placeholder="备注"
          />
          <div class="tips">请选择需上传交付物的项目</div>
          <van-tree-select
            :items="projects"
            :main-active-index.sync="activeIndex"
          >
            <template #content v-if="projects[activeIndex]">
              <div class="projectItem" v-for="item in projects[activeIndex].children" :key="item.id" @click="onTreeClick(item)">
                <div class="projectItemTxt">项目名称：{{item.name}}</div>
                <div class="projectItemTxt">薪酬：{{item.salaryRange}}</div>
                <div class="projectItemTxt">发布时间：{{item.createTime}}</div>
                <van-icon v-if="signUpId == item.id" class="projectItemChecked" name="success" size="20" color="#ee0a24"/>
              </div>
            </template>
          </van-tree-select>
        </div>

        <div class="footer">
          <van-button round block type="info" native-type="submit" :loading="upLoading" :disabled="upLoading" loading-text="加载中...">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
import Exif from 'exif-js'
export default {
  name: 'settlementInfo',
  data () {
    return {
      fj: [],
      realFj: [],
      files: {
        name: '',
        type: ''
      },
      remark: '',
      upLoading: false,

      projects: [],
      signUpId: '',
      activeIndex: 0
    }
  },
  mounted () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      this.initData()
      requestHTAPI.findProjects().then(res => {
        if (res.data.code === '0000') {
          const projects = JSON.parse(JSON.stringify(res.data.data))
          projects.forEach(item => {
            item.text = item.merchantName
            item.children = item.projectList
            delete item.merchantName
            delete item.projectList
          })
          this.projects = projects
        }
      })
    },
    initData () {
      this.fj = []
      this.realFj = []
      this.files = {
        name: '',
        type: ''
      }
      this.remark = ''
      this.upLoading = false
      this.projects = []
      this.signUpId = ''
      this.activeIndex = 0
    },
    // 上传处理
    afterRead (file, detail) {
      this.upLoading = true
      const formData = new FormData()
      formData.append('file', file.file)
      requestHTAPI.picZip(formData).then(res => {
        if (res.data.code == '0000') {
          this.realFj.push(res.data.data)
        } else {
          this.$notify({
            type: 'danger',
            message: res.data.message
          })
        }
        this.upLoading = false
      })
    },
    delImg (file, detail) {
      this.realFj.splice(detail.index, 1)
    },
    onTreeClick (v) {
      this.signUpId = v.id
    },
    onSubmit () {
      let arr = []
      arr = this.realFj.map(function (res) {
        return res
      })

      if (arr.length === 0) {
        this.$notify({
          type: 'danger',
          message: '请上传交付物附件'
        })
        return
      }

      if (!this.signUpId) {
        this.$notify({
          type: 'danger',
          message: '请选择需上传交付物的项目'
        })
        return
      }

      requestHTAPI.addDeliveryFileToFreelancer({
        files: arr,
        remark: this.remark,
        signUpId: this.signUpId
      }).then(res => {
        if (res.data.code === '0000') {
          this.onLoad()
          this.$notify({
            type: 'success',
            message: '提交成功'
          })
        } else {
          this.$notify({
            type: 'danger',
            message: res.data.message
          })
        }
      })
    },
    // 转base图片
    imgPreview (file, index) {
      const self = this
      let Orientation
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, 'Orientation')
      })
      if (!file || !window.FileReader) return
      if (/^image/.test(file.type)) {
        // 创建一个reader
        const reader = new FileReader()
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file)
        // 读取成功后的回调
        reader.onloadend = function () {
          const result = this.result
          const img = new Image()
          img.src = result
          // 判断图片是否大于500K,是就直接上传，反之压缩图片
          if (this.result.length <= 500 * 1024) {
            // 上传图片c
            self.postImg(this.result, index)
          } else {
            img.onload = function () {
              const data = self.compress(img, Orientation)
              // 上传图片
              self.postImg(data, index)
            }
          }
        }
      }
    },
    compress (img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      //    铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      if (Orientation != '' && Orientation != 1) {
        switch (Orientation) {
          case 6: // 需要顺时针（向左）90度旋转
            this.rotateImg(img, 'left', canvas)
            break
          case 8: // 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3: // 需要180度旋转
            this.rotateImg(img, 'right', canvas) // 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', 0.1)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
      return ndata
    },
    // 使用canvas压缩图片
    // 旋转图片
    rotateImg (img, direction, canvas) {
      // 最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      // img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction == 'right') {
        step++
        // 旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      // 旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, -width, 0)
          break
      }
    },
    // 将base64转换为文件
    dataURLtoFile (dataurl, filename) {
      filename = filename ? filename + '.png' : this.files.name
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      console.log(this.files)
      return new File([u8arr], filename, {
        type: mime || this.files.type
      })
    },
    postImg (base64, index) {
      const file = this.dataURLtoFile(base64)
      console.log('file', file)
      this.fj[index].file = file
    },
    urlToBase64 (url) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight
          // 将图片插入画布并开始绘制
          canvas.getContext('2d').drawImage(image, 0, 0)
          // result
          const result = canvas.toDataURL('image/png')
          resolve(result)
        }
        // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = url
        // 图片加载失败的错误处理
        image.onerror = () => {
          reject(new Error('urlToBase64 error'))
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .taskInfo {
    background: #eee;
  }

  .taskInfo-layout {
    text-align: left;
    background: #fff;
    .van-form {
      .body {
        padding: 10px 0 180px;
        .van-cell{
          /deep/ .van-field__label, .van-cell__title {
            margin-right: 0 !important;
            width: 5.6em !important;
          }
        }
      }
    }
  }

  .van-form {
    .uploaderRequire {
      /deep/ .van-cell__title.van-field__label {
        &:before {
          position: absolute;
          left: 1.8vw;
          color: #ee0a24;
          font-size: 3.73vw;
          content: '*';
          margin-top: 1vw;
        }
      }
    }
  }

  .tips {
    font-size: 24px;
    padding: 14px 26px;
    color: #999;
    &:before {
      position: absolute;
      left: 1.8vw;
      color: #ee0a24;
      font-size: 3.73vw;
      content: '*';
      margin-top: 1vw;
    }
  }

  .projectItem {
    padding: 20px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .projectItemTxt {
      padding: 4px 0;
    }
    .projectItemChecked {
      position: absolute;
      right: 20px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    margin: 16px;
    .van-button {
      width: 700px;
    }
  }
</style>
